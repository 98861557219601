main{
	.categories-wrapper{
		margin-top:80px;
		.title-g{
			margin-bottom:30px;
		}
		.categories-list{
			display:flex;
			flex-wrap:wrap;
			.box {
				max-width:350px;
				width:100%;
				height:180px;
				display:flex;
				border:1px solid #e1d0cf;
				position:relative;
				margin:9px auto;
				text-decoration: none;
				background-repeat:no-repeat;
				background-size:cover;
				.left {
					position:absolute;
					width:31%;
					height:180px;
					left:0;
					background-color:white;
					.text {
						color:#2a4dc6;
						font-weight:bold;
						text-transform: uppercase;
						font-size:18px;
						z-index:1;
						position:absolute;
						bottom:20px;
						left:10px;
					}
				}
				&:before {
					position:absolute;
					content:'';
					border-bottom: solid 0px white;
					border-left: solid 132px white;
					border-right: solid 120px transparent;
					border-top: solid 180px transparent;
					right:-10px;
					bottom:0;
					z-index:1;
				}
				&:hover{
					.left{
						background-color:#e9352e;
						.text{
							color:white;
						}
					}
					&:before{
						border-left: solid 132px #e9352e;
					}
				}
			}
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	main{
		.categories-wrapper{
			.categories-list{
				/*max-width:765px;*/
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	main{
		.categories-wrapper{
			max-width:100%;
			margin-top:40px;
		}
	}
}