#slider{
	.slide{
		display:flex!important;
		position:relative;
		width:100%;
		height:645px;
		z-index:1;
		.content{
			display:flex;
			width:53%;
			justify-content:flex-end;
			background-size:0;
			.text-box{
				max-width:630px;
				width:100%;
				color:white;
				margin-top:200px;
				padding-left:15px;
				padding-right:15px;
				box-sizing:border-box;
				.title{
					font-size:34px;
					font-weight:bold;
					line-height:1.5;
				}
				.text{
					font-size:16px;
					font-weight:400;
					line-height:1.5;
				}
			}         
		}
		.background{
			position:relative;
			width:47%;
			height:100%;
			background-repeat:no-repeat;
			background-size:cover;
			&:after{
				content:'';
				position:absolute;
				width:325px;
				height:100%;
				left:0;
				background-image:url(../images/slider-end.png);
			}
		}
	}
	.slider-dots{
		display:flex;
		position:absolute;
		list-style:none;
		padding:0;
		margin:auto;
		width:100%;
		left:0;
		right:0;
		bottom:60px;
		li{
			margin:0 2px;
			button{
				font-size:0;
				padding:0;
				width:8px;
				height:8px;
				background:none;
				border:1px solid #f1f1f1;
				cursor:pointer;
				outline:none;
			}
			&.slick-active button{
				background-color:#f1f1f1;
			}
		}
	}
}
#banner{
	display:flex!important;
	position:relative;
	width:100%;
	height:321px;
	top:0;
	z-index:1;
	.content{
		display:flex;
		justify-content:flex-end;
		width:53%;
		background-size:0;
		.title{
			max-width:610px;
			width:100%;
			font-size:34px;
			font-weight:bold;
			color:white;
			line-height:1.5;
			margin-top:150px;
			padding:0 15px;
			box-sizing:border-box;
		}
	}
	.background{
		position:relative;
		width:47%;
		height:100%;
		background-repeat:no-repeat;
		background-size:cover;
		&:after{
			content:'';
			position:absolute;
			width:322px;
			height:100%;
			left:0;
			background-image:url(../images/banner-end.png);
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	#slider{
		.slide{
			.content{
				.text-box{
					.title{
						font-size:30px;
					}
				}
			}
		}
		.slider-dots{
			max-width:initial;
			width:initial;
			margin:0 20px;
		}
	}
	#banner{
		.content{
			.title{
				font-size:30px;
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	#slider{
		.slide{
			.content{
				width:100%;
				justify-content:center;
				background-size:cover;
				.text-box{
					max-width:650px;
					height:fit-content;
					padding:20px;
					margin:200px auto 0;
					background-color:rgba(233, 53, 46, 0.9);
				}
			}
			.background{
				display:none;
			}
		}
	}
	#banner{
		.content{
			width:100%;
			justify-content:center;
			background-size:cover;
			.title{
				max-width:650px;
				height:fit-content;
				padding:20px;
				margin:130px auto 0;
				background-color:rgba(233, 53, 46, 0.9);
			}
		}
		.background{
			display:none;
		}
	}
}
/*RWD EXTRASMALL*/
@media #{$extra-small}{
	#slider{
		.slide{
			.content{
				.text-box{
					.title{
						font-size:25px;
						padding-bottom:20px;
					}
				}
			}
		}
	}
	#banner{
		.content{
			.title{
				font-size:25px;
			}
		}
	}
}