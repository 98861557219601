footer{
	.contact-wrapper{
		margin-top:70px;
		.cotact-box{
			display:flex;
			flex-direction:row;
			.contact-icons-box{
				display:flex;
				flex-direction:row;
				flex-wrap:wrap;
				width:60%;
				height:200px;
				.contact-icon{
					display:flex;
					flex-direction:row;
					align-items:center;
					font-size:17px;
					color:#021350;
					margin:10px;
					max-width:300px;
					width:100%;
					height:80px;
					img{
						width:70px;
						height:70px;
						object-fit:scale-down;
						margin-right:30px;
					}
					&:nth-of-type(2n+1){
						margin-left:0;
					}
				}
			}
			.contact-form{
				display:flex;
				flex-direction:column;
				width:40%;
				input, textarea{
					padding:10px 15px;
					margin:5px 0;
					border:1px solid #c1dae9;
				}
				input::placeholder{
					color:#021350;
				}
				textarea{
					min-height:100px;
					&::placeholder{
						color:#021350;
					}
				}
				.rodo{
					display:block;
					margin:10px 0;
					font-size:11px;
					input{
						margin:0;
					}
					a{
						font-size:11px;
						color:red;
						text-decoration:none;
					}
				}
				.btn-g{
					margin-top:30px;
					margin-right:30px;
					align-self:flex-end;
				}
			}
		}
	}
	.map-wrapper{
		display:flex;
		height:290px;
		margin-top:40px;
		.map-fb{
			display:flex;
			position:relative;
			align-items:center;
			justify-content:flex-end;
			width:50%;
			background-color:#e9352e;
			&:after{
				position:absolute;
				content:'';
				right:-144px;
				top:0;
				width:145px;
				height:290px;
				background-image:url(../images/map-end.png);
			}
			.fb{
				position:relative;
				width:262px;
				height:136px;
				margin:0 40px;
				background-image:url(../images/fb-map-bg.png);
				.fb-like{
					position:absolute;
					left:20px;
					top:80px;
				}
			}
		}
		.map{
			width:50%;
			height:100%;
		}
	}
	.main-menu{
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		justify-content:space-between;
		margin-top:20px;
		margin-bottom:20px;
		ul{
			display:flex;
			list-style:none;
			margin:0;
			padding:0;
			@media screen and (max-width:767px){
				display:none;
			}
			li{
				padding-right:25px;
				&:last-child{
					padding-right:0px;
				}
				a{
					color:#e9352e;
					font-size:16px;
					text-transform:uppercase;
					text-decoration:none;
					padding-bottom:20px;
					transition:0.3s;
					&:hover{
						border-bottom:3px solid #e9352e;
					}
				}
			}
		}
	}
	.copyright-info{
		padding:15px 0;
		border-top:1px solid #e9edef;
		.container{
			display:flex;
			justify-content:space-between;
			font-size:14px;
			color:#6c6b6b;
			a{
				color:#e9352e;
				text-decoration:none;
			}
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	footer{
		.contact-wrapper{
			.cotact-box{
				.contact-icons-box{
					height:initial;
				}
			}
		}
		.map-wrapper{
			.map-fb{
				justify-content:center;
				.title-g{
					display:none;
				}
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	footer{
		.contact-wrapper{
			margin-top:40px;
		}
		.main-menu{
			display:none;
		}
	}
}
/*RWD SMALL*/
@media #{$small}{
	footer{
		.contact-wrapper{
			.cotact-box{
				flex-direction:column;
				.contact-icons-box{
					width:100%;
					.contact-icon{
						max-width:250px;
						margin:0;
						font-size:15px;
						img{
							width:40px;
							height:40px;
							margin-right:10px;
						}
					}
				}
				.contact-form{
					width:100%;
					margin-top:30px;
				}
			}
		}
		.map-wrapper{
			.map-fb{
				display:none;
			}
			.map{
				width:100%;
			}
		}
		.copyright-info{
			.container{
				flex-direction:column;
				justify-content:center;
				align-items:center;
			}
		}
	}
}