/*VARIABLES*/
/*IMPORT LIBS*/
html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

/*! Pushy - v1.1.0 - 2017-1-30
* Pushy is a responsive off-canvas navigation menu using CSS transforms & transitions.
* https://github.com/christophery/pushy/
* by Christopher Yee */
/* Menu Appearance */
.pushy {
  position: fixed;
  max-width: 350px;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* enables momentum scrolling in iOS overflow elements */ }
  .pushy ul {
    list-style: none; }
  .pushy a {
    display: block;
    color: #e9352e;
    padding: 15px 30px;
    text-decoration: none; }
  .pushy a:hover {
    color: #e9352e; }
  .pushy ul:first-child {
    margin-top: 10px; }
  .pushy.pushy-left {
    left: 0; }
  .pushy.pushy-right {
    right: 0; }

.pushy-content {
  visibility: hidden;
  margin-top: 90px; }

/* Menu Movement */
.pushy-left {
  -webkit-transform: translate3d(-350px, 0, 0);
  -ms-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0); }

.pushy-open-left #container,
.pushy-open-left .push {
  -webkit-transform: translate3d(350px, 0, 0);
  -ms-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0); }

.pushy-right {
  -webkit-transform: translate3d(350px, 0, 0);
  -ms-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0); }

.pushy-open-right #container,
.pushy-open-right .push {
  -webkit-transform: translate3d(-350px, 0, 0);
  -ms-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0); }

.pushy-open-left .pushy,
.pushy-open-right .pushy {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.pushy-open-left .pushy-content,
.pushy-open-right .pushy-content {
  visibility: visible; }

/* Menu Transitions */
#container,
.pushy,
.push {
  transition: transform 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99); }

.pushy-content {
  transition: visibility 0.2s cubic-bezier(0.16, 0.68, 0.43, 0.99); }

/* Site Overlay */
.site-overlay {
  display: none; }

.pushy-open-left .site-overlay,
.pushy-open-right .site-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: fade 500ms;
  animation: fade 500ms; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Submenu Appearance */
.pushy-submenu {
  /* Submenu Buttons */
  /* Submenu Icon */ }
  .pushy-submenu ul {
    padding-left: 15px;
    transition: max-height 0.2s ease-in-out, visibility 0.2s ease-in-out; }
    .pushy-submenu ul .pushy-link {
      transition: opacity 0.2s ease-in-out; }
  .pushy-submenu button {
    width: 100%;
    color: #b3b3b1;
    padding: 15px 30px;
    text-align: left;
    background: transparent;
    border: 0; }
    .pushy-submenu button:hover {
      color: #FFF; }
  .pushy-submenu > a,
  .pushy-submenu > button {
    position: relative; }
  .pushy-submenu > a::after,
  .pushy-submenu > button::after {
    content: '';
    display: block;
    height: 11px;
    width: 8px;
    position: absolute;
    top: 50%;
    right: 15px;
    background: url("../img/arrow.svg") no-repeat;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: transform 0.2s; }

/* Submenu Movement */
.pushy-submenu-closed ul {
  max-height: 0;
  overflow: hidden;
  visibility: hidden; }

.pushy-submenu-closed .pushy-link {
  opacity: 0; }

.pushy-submenu-open {
  /* Submenu Icon */ }
  .pushy-submenu-open ul {
    max-height: 1000px;
    visibility: visible; }
  .pushy-submenu-open .pushy-link {
    opacity: 1; }
  .pushy-submenu-open a::after,
  .pushy-submenu-open button::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
    -ms-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg); }

/*Slick*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  height: 100%; }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: hidden; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-icon {
  margin: 0 0 15px; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-buttonswrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-loading {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-input {
  height: 32px;
  font-size: 14px;
  margin: 5px auto; }

body.swal2-toast-shown > .swal2-container {
  position: fixed;
  background-color: transparent; }

body.swal2-toast-shown > .swal2-container.swal2-shown {
  background-color: transparent; }

body.swal2-toast-shown > .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-top-right {
  top: 0;
  left: auto;
  bottom: auto;
  right: 0; }

body.swal2-toast-shown > .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
  bottom: auto;
  right: auto; }

body.swal2-toast-shown > .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  bottom: auto;
  right: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

body.swal2-toast-shown > .swal2-container.swal2-center-right {
  top: 50%;
  left: auto;
  bottom: auto;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-bottom-left {
  top: auto;
  left: 0;
  bottom: 0;
  right: auto; }

body.swal2-toast-shown > .swal2-container.swal2-bottom {
  top: auto;
  left: 50%;
  bottom: 0;
  right: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-toast-shown > .swal2-container.swal2-bottom-right {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0; }

body.swal2-iosfix {
  position: fixed;
  left: 0;
  right: 0; }

body.swal2-no-backdrop > .swal2-shown {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  background-color: transparent; }

body.swal2-no-backdrop > .swal2-shown > .swal2-modal {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }

body.swal2-no-backdrop > .swal2-shown.swal2-top {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-no-backdrop > .swal2-shown.swal2-top-left {
  top: 0;
  left: 0; }

body.swal2-no-backdrop > .swal2-shown.swal2-top-right {
  top: 0;
  right: 0; }

body.swal2-no-backdrop > .swal2-shown.swal2-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

body.swal2-no-backdrop > .swal2-shown.swal2-center-left {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-no-backdrop > .swal2-shown.swal2-center-right {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

body.swal2-no-backdrop > .swal2-shown.swal2-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

body.swal2-no-backdrop > .swal2-shown.swal2-bottom-left {
  bottom: 0;
  left: 0; }

body.swal2-no-backdrop > .swal2-shown.swal2-bottom-right {
  bottom: 0;
  right: 0; }

.swal2-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1060; }

.swal2-container.swal2-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.swal2-container.swal2-top-left {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.swal2-container.swal2-top-right {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.swal2-container.swal2-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.swal2-container.swal2-center-left {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.swal2-container.swal2-center-right {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.swal2-container.swal2-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.swal2-container.swal2-bottom-left {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.swal2-container.swal2-bottom-right {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.swal2-container.swal2-grow-row > .swal2-modal {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.swal2-container.swal2-grow-column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; }

.swal2-container.swal2-grow-column > .swal2-modal {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.swal2-container:not(.swal2-top):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-left):not(.swal2-bottom-right) > .swal2-modal {
  margin: auto; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important; } }

.swal2-container.swal2-fade {
  -webkit-transition: background-color .1s;
  transition: background-color .1s; }

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.4); }

.swal2-popup {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  position: relative;
  max-width: 100%; }

.swal2-popup.swal2-toast {
  width: 300px;
  padding: 0 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: hidden;
  -webkit-box-shadow: 0 0 10px #d9d9d9;
  box-shadow: 0 0 10px #d9d9d9; }

.swal2-popup.swal2-toast .swal2-title {
  max-width: 300px;
  font-size: 16px;
  text-align: left; }

.swal2-popup.swal2-toast .swal2-content {
  font-size: 14px;
  text-align: left; }

.swal2-popup.swal2-toast .swal2-icon {
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin: 0 15px 0 0; }

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 32px;
  height: 32px; }

.swal2-popup.swal2-toast .swal2-icon.swal2-info, .swal2-popup.swal2-toast .swal2-icon.swal2-warning, .swal2-popup.swal2-toast .swal2-icon.swal2-question {
  font-size: 26px;
  line-height: 32px; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  top: 14px;
  width: 22px; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 5px; }

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 5px; }

.swal2-popup.swal2-toast .swal2-buttonswrapper {
  margin: 0 0 0 5px; }

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 0 0 5px;
  padding: 5px 10px; }

.swal2-popup.swal2-toast .swal2-styled:focus {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(50, 100, 150, 0.4);
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(50, 100, 150, 0.4); }

.swal2-popup.swal2-toast .swal2-validationerror {
  width: 100%;
  margin: 5px -20px; }

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86; }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'] {
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 64px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  border-radius: 64px 0 0 64px;
  top: -4px;
  left: -15px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 32px 32px;
  transform-origin: 32px 32px; }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  border-radius: 0 64px 64px 0;
  top: -5px;
  left: 14px;
  -webkit-transform-origin: 0 32px;
  transform-origin: 0 32px; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 32px;
  height: 32px; }

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  width: 7px;
  height: 90px;
  left: 28px;
  top: 8px; }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'] {
  height: 5px; }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='tip'] {
  width: 12px;
  left: 3px;
  top: 18px; }

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='long'] {
  width: 22px;
  right: 3px;
  top: 15px; }

.swal2-popup.swal2-toast .swal2-animate-success-line-tip {
  -webkit-animation: animate-toast-success-tip .75s;
  animation: animate-toast-success-tip .75s; }

.swal2-popup.swal2-toast .swal2-animate-success-line-long {
  -webkit-animation: animate-toast-success-long .75s;
  animation: animate-toast-success-long .75s; }

.swal2-popup:focus {
  outline: none; }

.swal2-popup.swal2-loading {
  overflow-y: hidden; }

.swal2-popup .swal2-title {
  color: #595959;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: none;
  position: relative;
  margin: 0 0 .4em;
  padding: 0;
  display: block;
  word-wrap: break-word; }

.swal2-popup .swal2-buttonswrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 15px; }

.swal2-popup .swal2-buttonswrapper:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
  cursor: no-drop; }

.swal2-popup .swal2-buttonswrapper.swal2-loading .swal2-styled.swal2-confirm {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 4px solid transparent;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 7.5px;
  vertical-align: top;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  border-radius: 100%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-popup .swal2-buttonswrapper.swal2-loading .swal2-styled.swal2-cancel {
  margin-left: 30px;
  margin-right: 30px; }

.swal2-popup .swal2-buttonswrapper.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  display: inline-block;
  content: '';
  margin-left: 5px;
  vertical-align: -1px;
  height: 15px;
  width: 15px;
  border: 3px solid #999999;
  -webkit-box-shadow: 1px 1px 1px #fff;
  box-shadow: 1px 1px 1px #fff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  animation: rotate-loading 1.5s linear 0s infinite normal; }

.swal2-popup .swal2-styled {
  border: 0;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  margin: 15px 5px 0;
  padding: 10px 32px; }

.swal2-popup .swal2-styled:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4);
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4); }

.swal2-popup .swal2-image {
  margin: 20px auto;
  max-width: 100%; }

.swal2-popup .swal2-close {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 40px;
  font-size: 36px;
  line-height: 40px;
  font-family: serif;
  position: absolute;
  top: 5px;
  right: 8px;
  cursor: pointer;
  color: #cccccc;
  -webkit-transition: color .1s ease;
  transition: color .1s ease; }

.swal2-popup .swal2-close:hover {
  color: #d55; }

.swal2-popup > .swal2-input,
.swal2-popup > .swal2-file,
.swal2-popup > .swal2-textarea,
.swal2-popup > .swal2-select,
.swal2-popup > .swal2-radio,
.swal2-popup > .swal2-checkbox {
  display: none; }

.swal2-popup .swal2-content {
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  position: relative;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #545454;
  word-wrap: break-word; }

.swal2-popup .swal2-input,
.swal2-popup .swal2-file,
.swal2-popup .swal2-textarea,
.swal2-popup .swal2-select,
.swal2-popup .swal2-radio,
.swal2-popup .swal2-checkbox {
  margin: 20px auto; }

.swal2-popup .swal2-input,
.swal2-popup .swal2-file,
.swal2-popup .swal2-textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  -webkit-transition: border-color .3s, -webkit-box-shadow .3s;
  transition: border-color .3s, -webkit-box-shadow .3s;
  transition: border-color .3s, box-shadow .3s;
  transition: border-color .3s, box-shadow .3s, -webkit-box-shadow .3s; }

.swal2-popup .swal2-input.swal2-inputerror,
.swal2-popup .swal2-file.swal2-inputerror,
.swal2-popup .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  -webkit-box-shadow: 0 0 2px #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important; }

.swal2-popup .swal2-input:focus,
.swal2-popup .swal2-file:focus,
.swal2-popup .swal2-textarea:focus {
  outline: none;
  border: 1px solid #b4dbed;
  -webkit-box-shadow: 0 0 3px #c4e6f5;
  box-shadow: 0 0 3px #c4e6f5; }

.swal2-popup .swal2-input::-webkit-input-placeholder,
.swal2-popup .swal2-file::-webkit-input-placeholder,
.swal2-popup .swal2-textarea::-webkit-input-placeholder {
  color: #cccccc; }

.swal2-popup .swal2-input:-ms-input-placeholder,
.swal2-popup .swal2-file:-ms-input-placeholder,
.swal2-popup .swal2-textarea:-ms-input-placeholder {
  color: #cccccc; }

.swal2-popup .swal2-input::-ms-input-placeholder,
.swal2-popup .swal2-file::-ms-input-placeholder,
.swal2-popup .swal2-textarea::-ms-input-placeholder {
  color: #cccccc; }

.swal2-popup .swal2-input::placeholder,
.swal2-popup .swal2-file::placeholder,
.swal2-popup .swal2-textarea::placeholder {
  color: #cccccc; }

.swal2-popup .swal2-range input {
  float: left;
  width: 80%; }

.swal2-popup .swal2-range output {
  float: right;
  width: 20%;
  font-size: 20px;
  font-weight: 600;
  text-align: center; }

.swal2-popup .swal2-range input,
.swal2-popup .swal2-range output {
  height: 43px;
  line-height: 43px;
  vertical-align: middle;
  margin: 20px auto;
  padding: 0; }

.swal2-popup .swal2-input {
  height: 43px;
  padding: 0 12px; }

.swal2-popup .swal2-input[type='number'] {
  max-width: 150px; }

.swal2-popup .swal2-file {
  font-size: 20px; }

.swal2-popup .swal2-textarea {
  height: 108px;
  padding: 12px; }

.swal2-popup .swal2-select {
  color: #545454;
  font-size: inherit;
  padding: 5px 10px;
  min-width: 40%;
  max-width: 100%; }

.swal2-popup .swal2-radio {
  border: 0; }

.swal2-popup .swal2-radio label:not(:first-child) {
  margin-left: 20px; }

.swal2-popup .swal2-radio input,
.swal2-popup .swal2-radio span {
  vertical-align: middle; }

.swal2-popup .swal2-radio input {
  margin: 0 3px 0 0; }

.swal2-popup .swal2-checkbox {
  color: #545454; }

.swal2-popup .swal2-checkbox input,
.swal2-popup .swal2-checkbox span {
  vertical-align: middle; }

.swal2-popup .swal2-validationerror {
  background-color: #f0f0f0;
  margin: 0 -20px;
  overflow: hidden;
  padding: 10px;
  color: gray;
  font-size: 16px;
  font-weight: 300;
  display: none; }

.swal2-popup .swal2-validationerror::before {
  content: '!';
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: #fff;
  line-height: 24px;
  text-align: center;
  margin-right: 10px; }

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important; }
  .swal2-range output {
    display: none; } }

.swal2-icon {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.swal2-icon.swal2-error {
  border-color: #f27474; }

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  display: block; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #f27474;
  display: block;
  top: 37px;
  border-radius: 2px; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px; }

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px; }

.swal2-icon.swal2-warning {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #f8bb86;
  border-color: #facea8;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-info {
  font-family: 'Open Sans', sans-serif;
  color: #3fc3ee;
  border-color: #9de0f6;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-question {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #87adbd;
  border-color: #c9dae1;
  font-size: 60px;
  line-height: 80px;
  text-align: center; }

.swal2-icon.swal2-success {
  border-color: #a5dc86; }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px; }

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px; }

.swal2-icon.swal2-success .swal2-success-ring {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 50%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.swal2-icon.swal2-success .swal2-success-fix {
  width: 7px;
  height: 90px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.swal2-progresssteps {
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0; }

.swal2-progresssteps li {
  display: inline-block;
  position: relative; }

.swal2-progresssteps .swal2-progresscircle {
  background: #3085d6;
  border-radius: 2em;
  color: #fff;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
  z-index: 20; }

.swal2-progresssteps .swal2-progresscircle:first-child {
  margin-left: 0; }

.swal2-progresssteps .swal2-progresscircle:last-child {
  margin-right: 0; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #3085d6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  background: #add8e6; }

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #add8e6; }

.swal2-progresssteps .swal2-progressline {
  background: #3085d6;
  height: .4em;
  margin: 0 -1px;
  z-index: 10; }

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent; }

@-webkit-keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-10px) rotateZ(2deg);
    transform: translateY(-10px) rotateZ(2deg);
    opacity: 0; }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5; }
  66% {
    -webkit-transform: translateY(5px) rotateZ(2deg);
    transform: translateY(5px) rotateZ(2deg);
    opacity: .7; }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1; } }

@keyframes showSweetToast {
  0% {
    -webkit-transform: translateY(-10px) rotateZ(2deg);
    transform: translateY(-10px) rotateZ(2deg);
    opacity: 0; }
  33% {
    -webkit-transform: translateY(0) rotateZ(-2deg);
    transform: translateY(0) rotateZ(-2deg);
    opacity: .5; }
  66% {
    -webkit-transform: translateY(5px) rotateZ(2deg);
    transform: translateY(5px) rotateZ(2deg);
    opacity: .7; }
  100% {
    -webkit-transform: translateY(0) rotateZ(0);
    transform: translateY(0) rotateZ(0);
    opacity: 1; } }

@-webkit-keyframes hideSweetToast {
  0% {
    opacity: 1; }
  33% {
    opacity: .5; }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0; } }

@keyframes hideSweetToast {
  0% {
    opacity: 1; }
  33% {
    opacity: .5; }
  100% {
    -webkit-transform: rotateZ(1deg);
    transform: rotateZ(1deg);
    opacity: 0; } }

@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7); }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; } }

@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0; } }

.swal2-show {
  -webkit-animation: showSweetAlert .3s;
  animation: showSweetAlert .3s; }

.swal2-show.swal2-toast {
  -webkit-animation: showSweetToast .5s;
  animation: showSweetToast .5s; }

.swal2-show.swal2-noanimation {
  -webkit-animation: none;
  animation: none; }

.swal2-hide {
  -webkit-animation: hideSweetAlert .15s forwards;
  animation: hideSweetAlert .15s forwards; }

.swal2-hide.swal2-toast {
  -webkit-animation: hideSweetToast .2s forwards;
  animation: hideSweetToast .2s forwards; }

.swal2-hide.swal2-noanimation {
  -webkit-animation: none;
  animation: none; }

[dir='rtl'] .swal2-close {
  left: 8px;
  right: auto; }

@-webkit-keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@-webkit-keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes animate-toast-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 9px; }
  54% {
    width: 0;
    left: 1px;
    top: 9px; }
  70% {
    width: 24px;
    left: -4px;
    top: 17px; }
  84% {
    width: 8px;
    left: 10px;
    top: 20px; }
  100% {
    width: 12px;
    left: 3px;
    top: 18px; } }

@keyframes animate-toast-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 9px; }
  54% {
    width: 0;
    left: 1px;
    top: 9px; }
  70% {
    width: 24px;
    left: -4px;
    top: 17px; }
  84% {
    width: 8px;
    left: 10px;
    top: 20px; }
  100% {
    width: 12px;
    left: 3px;
    top: 18px; } }

@-webkit-keyframes animate-toast-success-long {
  0% {
    width: 0;
    right: 22px;
    top: 26px; }
  65% {
    width: 0;
    right: 22px;
    top: 26px; }
  84% {
    width: 26px;
    right: 0;
    top: 15px; }
  100% {
    width: 22px;
    right: 3px;
    top: 15px; } }

@keyframes animate-toast-success-long {
  0% {
    width: 0;
    right: 22px;
    top: 26px; }
  65% {
    width: 0;
    right: 22px;
    top: 26px; }
  84% {
    width: 26px;
    right: 0;
    top: 15px; }
  100% {
    width: 22px;
    right: 3px;
    top: 15px; } }

@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); } }

.swal2-animate-success-line-tip {
  -webkit-animation: animate-success-tip .75s;
  animation: animate-success-tip .75s; }

.swal2-animate-success-line-long {
  -webkit-animation: animate-success-long .75s;
  animation: animate-success-long .75s; }

.swal2-success.swal2-animate-success-icon .swal2-success-circular-line-right {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

@-webkit-keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1; } }

.swal2-animate-error-icon {
  -webkit-animation: animate-error-icon .5s;
  animation: animate-error-icon .5s; }

@-webkit-keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

@keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0; }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1; } }

.swal2-animate-x-mark {
  -webkit-animation: animate-x-mark .5s;
  animation: animate-x-mark .5s; }

@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*IMPORT TEMPLATES*/
body {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  overflow-x: hidden; }

.container {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box; }

.btn-g {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 40px;
  margin-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  color: white;
  font-weight: 500;
  text-decoration: none;
  background: #e9352e;
  border: none;
  cursor: pointer; }
  .btn-g:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 30px;
    background: #e9352e;
    transform: rotate(45deg);
    right: -15px;
    z-index: -1; }
  .btn-g.btn-white {
    color: #e9352e;
    font-weight: bold;
    background: white; }
    .btn-g.btn-white:before {
      background: white; }

.title-g {
  color: #e9352e;
  font-size: 30px;
  font-weight: bold; }
  .title-g.title-white {
    color: white; }

.content-g {
  margin: 20px 0 40px; }

.clear-g {
  clear: both; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  .container {
    max-width: 960px; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  .container {
    max-width: 720px; } }

/*RWD SMALL*/
@media (max-width: 767px) {
  .container {
    max-width: 540px; } }

.pushy-open-right {
  /*.menu-btn{
		transition:5s;
		z-index:99999;
		cursor:pointer;
		&:focus{
			outline:0!important;
		}
		span{
			background:#e9352e!important;
		}
		.close{
			height:37px;
			width:37px;
			&:focus{
				outline:0!important;
			}
		}
		.close:after{
			position:relative;
			content:"\d7";
			font-size:100px;
			color:white;
			font-weight:normal;
			top:-40px;
			left:-2px
		}
	}*/ }
  .pushy-open-right .menu-close {
    display: none; }

.cookies {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 999;
  transition: bottom .3s ease-in-out; }
  .cookies.close {
    bottom: -150px; }
  .cookies .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    width: 98%;
    margin: 0 auto 20px;
    padding: 20px 0;
    background-color: white;
    box-shadow: 3px 2px 5px 2px rgba(0, 0, 0, 0.2); }
    .cookies .container .info {
      width: 15%;
      color: #e9352e;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      padding: 20px;
      border-right: 1px solid #d3d4d6; }
    .cookies .container .text {
      width: 70%;
      padding: 20px;
      color: black;
      font-size: 13px;
      font-weight: 300; }
    .cookies .container .accept {
      display: flex;
      justify-content: center;
      align-items: Center;
      max-width: 100px;
      width: 100%;
      height: 40px;
      color: white;
      padding: 0 20px;
      background-color: #e9352e;
      cursor: pointer; }

header {
  position: relative;
  background-color: #e9352e; }
  header .main-menu {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    align-items: center;
    left: 0;
    right: 0;
    top: 15px;
    z-index: 9999; }
    header .main-menu ul {
      display: flex;
      list-style: none;
      margin-left: 130px; }
      header .main-menu ul li {
        padding-right: 25px; }
        header .main-menu ul li:last-child {
          padding-right: 0px; }
        header .main-menu ul li a {
          color: #ffffff;
          font-size: 16px;
          text-transform: uppercase;
          text-decoration: none;
          padding-bottom: 20px;
          transition: 0.3s; }
          header .main-menu ul li a:hover {
            border-bottom: 3px solid white; }
    header .main-menu .menu-btn {
      display: none;
      position: absolute;
      right: 40px;
      top: 20px;
      background: none;
      border: none;
      cursor: pointer;
      outline: none; }
      header .main-menu .menu-btn span {
        display: block;
        width: 40px;
        height: 5px;
        margin-bottom: 5px;
        background-color: white;
        border: none; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  header .main-menu {
    max-width: initial; }
    header .main-menu ul {
      margin-left: 40px; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  header {
    background-color: transparent; }
    header .main-menu ul {
      display: none; }
    header .main-menu .menu-btn {
      display: block; }
  .pushy-open-right header .main-menu .menu-btn {
    height: 35px; }
    .pushy-open-right header .main-menu .menu-btn span {
      background-color: black; }
      .pushy-open-right header .main-menu .menu-btn span:nth-of-type(1) {
        transform: rotateZ(45deg); }
      .pushy-open-right header .main-menu .menu-btn span:nth-of-type(2) {
        margin-top: -10px;
        transform: rotateZ(-45deg); }
      .pushy-open-right header .main-menu .menu-btn span:last-of-type {
        display: none; } }

/*RWD SMALL*/
@media (max-width: 767px) {
  .cookies .container .info {
    width: 100%;
    padding-bottom: 30px;
    border-right: 0px; }
  header .main-menu ul {
    display: none; } }

/*RWD EXTRASMALL*/
@media (max-width: 576px) {
  header {
    padding-bottom: 40px; } }

#slider .slide {
  display: flex !important;
  position: relative;
  width: 100%;
  height: 645px;
  z-index: 1; }
  #slider .slide .content {
    display: flex;
    width: 53%;
    justify-content: flex-end;
    background-size: 0; }
    #slider .slide .content .text-box {
      max-width: 630px;
      width: 100%;
      color: white;
      margin-top: 200px;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box; }
      #slider .slide .content .text-box .title {
        font-size: 34px;
        font-weight: bold;
        line-height: 1.5; }
      #slider .slide .content .text-box .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5; }
  #slider .slide .background {
    position: relative;
    width: 47%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; }
    #slider .slide .background:after {
      content: '';
      position: absolute;
      width: 325px;
      height: 100%;
      left: 0;
      background-image: url(../images/slider-end.png); }

#slider .slider-dots {
  display: flex;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: auto;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 60px; }
  #slider .slider-dots li {
    margin: 0 2px; }
    #slider .slider-dots li button {
      font-size: 0;
      padding: 0;
      width: 8px;
      height: 8px;
      background: none;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      outline: none; }
    #slider .slider-dots li.slick-active button {
      background-color: #f1f1f1; }

#banner {
  display: flex !important;
  position: relative;
  width: 100%;
  height: 321px;
  top: 0;
  z-index: 1; }
  #banner .content {
    display: flex;
    justify-content: flex-end;
    width: 53%;
    background-size: 0; }
    #banner .content .title {
      max-width: 610px;
      width: 100%;
      font-size: 34px;
      font-weight: bold;
      color: white;
      line-height: 1.5;
      margin-top: 150px;
      padding: 0 15px;
      box-sizing: border-box; }
  #banner .background {
    position: relative;
    width: 47%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover; }
    #banner .background:after {
      content: '';
      position: absolute;
      width: 322px;
      height: 100%;
      left: 0;
      background-image: url(../images/banner-end.png); }

/*RWD LARGE*/
@media (max-width: 1199px) {
  #slider .slide .content .text-box .title {
    font-size: 30px; }
  #slider .slider-dots {
    max-width: initial;
    width: initial;
    margin: 0 20px; }
  #banner .content .title {
    font-size: 30px; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  #slider .slide .content {
    width: 100%;
    justify-content: center;
    background-size: cover; }
    #slider .slide .content .text-box {
      max-width: 650px;
      height: fit-content;
      padding: 20px;
      margin: 200px auto 0;
      background-color: rgba(233, 53, 46, 0.9); }
  #slider .slide .background {
    display: none; }
  #banner .content {
    width: 100%;
    justify-content: center;
    background-size: cover; }
    #banner .content .title {
      max-width: 650px;
      height: fit-content;
      padding: 20px;
      margin: 130px auto 0;
      background-color: rgba(233, 53, 46, 0.9); }
  #banner .background {
    display: none; } }

/*RWD EXTRASMALL*/
@media (max-width: 576px) {
  #slider .slide .content .text-box .title {
    font-size: 25px;
    padding-bottom: 20px; }
  #banner .content .title {
    font-size: 25px; } }

footer .contact-wrapper {
  margin-top: 70px; }
  footer .contact-wrapper .cotact-box {
    display: flex;
    flex-direction: row; }
    footer .contact-wrapper .cotact-box .contact-icons-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 60%;
      height: 200px; }
      footer .contact-wrapper .cotact-box .contact-icons-box .contact-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 17px;
        color: #021350;
        margin: 10px;
        max-width: 300px;
        width: 100%;
        height: 80px; }
        footer .contact-wrapper .cotact-box .contact-icons-box .contact-icon img {
          width: 70px;
          height: 70px;
          object-fit: scale-down;
          margin-right: 30px; }
        footer .contact-wrapper .cotact-box .contact-icons-box .contact-icon:nth-of-type(2n+1) {
          margin-left: 0; }
    footer .contact-wrapper .cotact-box .contact-form {
      display: flex;
      flex-direction: column;
      width: 40%; }
      footer .contact-wrapper .cotact-box .contact-form input, footer .contact-wrapper .cotact-box .contact-form textarea {
        padding: 10px 15px;
        margin: 5px 0;
        border: 1px solid #c1dae9; }
      footer .contact-wrapper .cotact-box .contact-form input::placeholder {
        color: #021350; }
      footer .contact-wrapper .cotact-box .contact-form textarea {
        min-height: 100px; }
        footer .contact-wrapper .cotact-box .contact-form textarea::placeholder {
          color: #021350; }
      footer .contact-wrapper .cotact-box .contact-form .rodo {
        display: block;
        margin: 10px 0;
        font-size: 11px; }
        footer .contact-wrapper .cotact-box .contact-form .rodo input {
          margin: 0; }
        footer .contact-wrapper .cotact-box .contact-form .rodo a {
          font-size: 11px;
          color: red;
          text-decoration: none; }
      footer .contact-wrapper .cotact-box .contact-form .btn-g {
        margin-top: 30px;
        margin-right: 30px;
        align-self: flex-end; }

footer .map-wrapper {
  display: flex;
  height: 290px;
  margin-top: 40px; }
  footer .map-wrapper .map-fb {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    background-color: #e9352e; }
    footer .map-wrapper .map-fb:after {
      position: absolute;
      content: '';
      right: -144px;
      top: 0;
      width: 145px;
      height: 290px;
      background-image: url(../images/map-end.png); }
    footer .map-wrapper .map-fb .fb {
      position: relative;
      width: 262px;
      height: 136px;
      margin: 0 40px;
      background-image: url(../images/fb-map-bg.png); }
      footer .map-wrapper .map-fb .fb .fb-like {
        position: absolute;
        left: 20px;
        top: 80px; }
  footer .map-wrapper .map {
    width: 50%;
    height: 100%; }

footer .main-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px; }
  footer .main-menu ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 767px) {
      footer .main-menu ul {
        display: none; } }
    footer .main-menu ul li {
      padding-right: 25px; }
      footer .main-menu ul li:last-child {
        padding-right: 0px; }
      footer .main-menu ul li a {
        color: #e9352e;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        padding-bottom: 20px;
        transition: 0.3s; }
        footer .main-menu ul li a:hover {
          border-bottom: 3px solid #e9352e; }

footer .copyright-info {
  padding: 15px 0;
  border-top: 1px solid #e9edef; }
  footer .copyright-info .container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #6c6b6b; }
    footer .copyright-info .container a {
      color: #e9352e;
      text-decoration: none; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  footer .contact-wrapper .cotact-box .contact-icons-box {
    height: initial; }
  footer .map-wrapper .map-fb {
    justify-content: center; }
    footer .map-wrapper .map-fb .title-g {
      display: none; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  footer .contact-wrapper {
    margin-top: 40px; }
  footer .main-menu {
    display: none; } }

/*RWD SMALL*/
@media (max-width: 767px) {
  footer .contact-wrapper .cotact-box {
    flex-direction: column; }
    footer .contact-wrapper .cotact-box .contact-icons-box {
      width: 100%; }
      footer .contact-wrapper .cotact-box .contact-icons-box .contact-icon {
        max-width: 250px;
        margin: 0;
        font-size: 15px; }
        footer .contact-wrapper .cotact-box .contact-icons-box .contact-icon img {
          width: 40px;
          height: 40px;
          margin-right: 10px; }
    footer .contact-wrapper .cotact-box .contact-form {
      width: 100%;
      margin-top: 30px; }
  footer .map-wrapper .map-fb {
    display: none; }
  footer .map-wrapper .map {
    width: 100%; }
  footer .copyright-info .container {
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

/*IMPORT VIEWS*/
main .subpage-page {
  margin-top: 80px; }
  main .subpage-page .text {
    margin-top: 40px; }
    main .subpage-page .text .icon {
      float: left;
      margin: 0 30px 20px 0;
      width: 360px;
      height: 243px;
      object-fit: cover; }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  main .subpage-page .text .icon {
    display: block;
    float: none;
    margin: auto; } }

main .aboutus-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 80px; }
  main .aboutus-wrapper .content {
    max-width: 625px;
    width: 100%; }
    main .aboutus-wrapper .content .text {
      color: #021350;
      font-size: 16px;
      font-weight: 400;
      padding-top: 20px;
      line-height: 1.5;
      width: 90%; }
    main .aboutus-wrapper .content .btn-more {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      background: #e9352e;
      color: white;
      width: fit-content;
      text-decoration: none;
      font-weight: 500;
      position: relative;
      margin-top: 40px; }
      main .aboutus-wrapper .content .btn-more:before {
        position: absolute;
        content: '';
        height: 28px;
        width: 30px;
        background: #e9352e;
        transform: rotate(45deg);
        right: -15px;
        z-index: -1; }
  main .aboutus-wrapper .icon-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 455px;
    width: 100%;
    max-height: 350px;
    padding-top: 40px; }
    main .aboutus-wrapper .icon-wrapper .box {
      max-width: 200px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 30px; }
      main .aboutus-wrapper .icon-wrapper .box .text {
        color: #021350;
        font-size: 13px;
        font-weight: bold;
        width: 165px;
        text-align: center;
        padding-top: 20px;
        text-transform: uppercase; }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  main .aboutus-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px; }
    main .aboutus-wrapper .icon-wrapper {
      padding-top: 50px; } }

main .categories-wrapper {
  margin-top: 80px; }
  main .categories-wrapper .title-g {
    margin-bottom: 30px; }
  main .categories-wrapper .categories-list {
    display: flex;
    flex-wrap: wrap; }
    main .categories-wrapper .categories-list .box {
      max-width: 350px;
      width: 100%;
      height: 180px;
      display: flex;
      border: 1px solid #e1d0cf;
      position: relative;
      margin: 9px auto;
      text-decoration: none;
      background-repeat: no-repeat;
      background-size: cover; }
      main .categories-wrapper .categories-list .box .left {
        position: absolute;
        width: 31%;
        height: 180px;
        left: 0;
        background-color: white; }
        main .categories-wrapper .categories-list .box .left .text {
          color: #2a4dc6;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          z-index: 1;
          position: absolute;
          bottom: 20px;
          left: 10px; }
      main .categories-wrapper .categories-list .box:before {
        position: absolute;
        content: '';
        border-bottom: solid 0px white;
        border-left: solid 132px white;
        border-right: solid 120px transparent;
        border-top: solid 180px transparent;
        right: -10px;
        bottom: 0;
        z-index: 1; }
      main .categories-wrapper .categories-list .box:hover .left {
        background-color: #e9352e; }
        main .categories-wrapper .categories-list .box:hover .left .text {
          color: white; }
      main .categories-wrapper .categories-list .box:hover:before {
        border-left: solid 132px #e9352e; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  main .categories-wrapper .categories-list {
    /*max-width:765px;*/ } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  main .categories-wrapper {
    max-width: 100%;
    margin-top: 40px; } }

main .news-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 130px; }
  main .news-wrapper .news-container {
    display: flex;
    position: relative;
    width: 60%;
    height: 545px;
    background: #e9352e; }
    main .news-wrapper .news-container:after {
      position: absolute;
      content: '';
      width: 518px;
      height: 674px;
      left: -405px;
      top: -80px;
      background-image: url(../images/news-wrapper.png);
      background-repeat: no-repeat; }
    main .news-wrapper .news-container .content {
      position: relative;
      max-width: 555px;
      width: 100%;
      margin: 80px 0 0 140px;
      padding: 0 20px; }
      main .news-wrapper .news-container .content .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        z-index: 1;
        margin-bottom: 30px; }
        main .news-wrapper .news-container .content .nav a {
          color: white;
          font-size: 13px;
          text-decoration: none; }
      main .news-wrapper .news-container .content .news .news-icon {
        max-width: 555px;
        width: 100%;
        height: 147px;
        object-fit: cover; }
      main .news-wrapper .news-container .content .news .date {
        color: white;
        font-size: 14px;
        font-weight: 300;
        margin-top: 30px; }
      main .news-wrapper .news-container .content .news .title {
        display: block;
        color: white;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        margin: 5px 0 20px; }
      main .news-wrapper .news-container .content .news .text {
        font-size: 15px;
        color: white; }
      main .news-wrapper .news-container .content .news .news-readmore {
        display: block;
        font-size: 15px;
        color: white;
        text-decoration: none;
        margin-top: 15px; }

main .news-list-page {
  margin-top: 80px; }
  main .news-list-page .news-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px; }
    main .news-list-page .news-list .news {
      width: 355px;
      margin: 25px 0;
      color: #021350; }
      main .news-list-page .news-list .news .icon {
        display: block;
        margin-bottom: 30px;
        font-size: 0;
        border: 1px solid #e1d0cf; }
        main .news-list-page .news-list .news .icon img {
          width: 353px;
          height: 147px;
          object-fit: cover; }
        main .news-list-page .news-list .news .icon.default img {
          object-fit: contain; }
      main .news-list-page .news-list .news .date {
        font-size: 14px; }
      main .news-list-page .news-list .news .title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #021350;
        text-decoration: none;
        margin: 5px 0 10px; }
      main .news-list-page .news-list .news .content {
        font-size: 16px; }
      main .news-list-page .news-list .news .readmore {
        display: block;
        font-size: 16px;
        color: #021350;
        text-decoration: none;
        margin-top: 10px; }

main .news-page {
  margin-top: 80px; }
  main .news-page .text {
    margin-top: 40px; }
    main .news-page .text .icon {
      float: left;
      margin: 0 30px 20px 0;
      width: 360px;
      height: 243px;
      object-fit: cover; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  main .news-wrapper .news-container:after {
    width: 435px;
    height: 575px;
    left: -460px;
    top: 0px;
    background-size: 100%; }
  main .news-wrapper .news-container .content {
    margin-left: 40px; }
  main .news-list-page .news-list {
    justify-content: center; }
    main .news-list-page .news-list .news {
      margin: 25px 10px; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  main .news-wrapper {
    margin-top: 40px; }
    main .news-wrapper .news-container {
      width: 100%; }
      main .news-wrapper .news-container:after {
        display: none; }
      main .news-wrapper .news-container .content {
        margin: auto; }
  main .news-page .text .icon {
    display: block;
    float: none;
    margin: auto; } }

main .contact-page {
  margin-top: 70px; }
  main .contact-page .cotact-box {
    display: flex;
    flex-direction: row; }
    main .contact-page .cotact-box .contact-icons-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 60%;
      height: 200px; }
      main .contact-page .cotact-box .contact-icons-box .contact-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 17px;
        color: #021350;
        margin: 10px;
        max-width: 300px;
        width: 100%;
        height: 80px; }
        main .contact-page .cotact-box .contact-icons-box .contact-icon img {
          width: 70px;
          height: 70px;
          object-fit: scale-down;
          margin-right: 30px; }
        main .contact-page .cotact-box .contact-icons-box .contact-icon:nth-of-type(2n+1) {
          margin-left: 0; }
    main .contact-page .cotact-box .contact-form {
      display: flex;
      flex-direction: column;
      width: 40%; }
      main .contact-page .cotact-box .contact-form input, main .contact-page .cotact-box .contact-form textarea {
        padding: 10px 15px;
        margin: 5px 0;
        border: 1px solid #c1dae9; }
      main .contact-page .cotact-box .contact-form input::placeholder {
        color: #021350; }
      main .contact-page .cotact-box .contact-form textarea {
        min-height: 100px; }
        main .contact-page .cotact-box .contact-form textarea::placeholder {
          color: #021350; }
      main .contact-page .cotact-box .contact-form .rodo {
        display: block;
        margin: 10px 0;
        font-size: 11px; }
        main .contact-page .cotact-box .contact-form .rodo input {
          margin: 0; }
        main .contact-page .cotact-box .contact-form .rodo a {
          font-size: 11px;
          color: red;
          text-decoration: none; }
      main .contact-page .cotact-box .contact-form .btn-g {
        margin-top: 30px;
        margin-right: 30px;
        align-self: flex-end; }

/*RWD LARGE*/
@media (max-width: 1199px) {
  main .contact-page .cotact-box .contact-icons-box {
    height: initial; } }

/*RWD MEDIUM*/
@media (max-width: 991px) {
  main .contact-page {
    margin-top: 40px; } }

/*RWD SMALL*/
@media (max-width: 767px) {
  main .contact-page .cotact-box {
    flex-direction: column; }
    main .contact-page .cotact-box .contact-icons-box {
      width: 100%; }
      main .contact-page .cotact-box .contact-icons-box .contact-icon {
        max-width: 250px;
        margin: 0;
        font-size: 15px; }
        main .contact-page .cotact-box .contact-icons-box .contact-icon img {
          width: 40px;
          height: 40px;
          margin-right: 10px; }
    main .contact-page .cotact-box .contact-form {
      width: 100%;
      margin-top: 30px; } }

main .gallery-wrapper {
  margin-top: 40px; }
  main .gallery-wrapper .gallery-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px; }
    main .gallery-wrapper .gallery-list .gallery-item {
      display: block;
      position: relative;
      margin: 10px;
      font-size: 0;
      overflow: hidden; }
      main .gallery-wrapper .gallery-list .gallery-item:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: -100%;
        top: 0;
        background-color: rgba(233, 53, 46, 0.8);
        background-image: url(../images/zoom-icon.png);
        background-repeat: no-repeat;
        background-position: center;
        transition: left .3s; }
      main .gallery-wrapper .gallery-list .gallery-item:hover:after {
        left: 0; }
      main .gallery-wrapper .gallery-list .gallery-item img {
        width: 250px;
        height: 200px;
        object-fit: cover; }

/*RWD MEDIUM*/
