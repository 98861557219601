.pushy-open-right{
	/*.menu-btn{
		transition:5s;
		z-index:99999;
		cursor:pointer;
		&:focus{
			outline:0!important;
		}
		span{
			background:#e9352e!important;
		}
		.close{
			height:37px;
			width:37px;
			&:focus{
				outline:0!important;
			}
		}
		.close:after{
			position:relative;
			content:"\d7";
			font-size:100px;
			color:white;
			font-weight:normal;
			top:-40px;
			left:-2px
		}
	}*/
	.menu-close{
		display:none;
	}
}
.cookies{
	display:none;
	position:fixed;
	bottom:0;
	z-index:999;
	transition:bottom .3s ease-in-out;
	&.close{
		bottom:-150px;
	}
	.container{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		max-width:1920px;
		width:98%;
		margin:0 auto 20px;
		padding:20px 0;
		background-color:white;
		box-shadow:3px 2px 5px 2px rgba(0,0,0,0.2);
		.info{
			width:15%;
			color:#e9352e;
			font-size:20px;
			font-weight:500;
			text-align:center;
			padding:20px;
			border-right:1px solid #d3d4d6;
		}
		.text{
			width:70%;
			padding:20px;
			color:black;
			font-size:13px;
			font-weight:300;
		}
		.accept{
			display:flex;
			justify-content:center;
			align-items:Center;
			max-width:100px;
			width:100%;
			height:40px;
			color:white;
			padding:0 20px;
			background-color:#e9352e;
			cursor:pointer;
		}
	}
}
header{
	position:relative;
	background-color:#e9352e;
	.main-menu{
		display:flex;
		position:absolute;
		flex-wrap:wrap;
		align-items:center;
		left:0;
		right:0;
		top:15px;
		z-index:9999;
		ul{
			display:flex;
			list-style:none;
			margin-left:130px;
			li{    
				padding-right:25px;
				&:last-child{
					padding-right:0px;
				}
				a{
					color:#ffffff;
					font-size:16px;
					text-transform:uppercase;
					text-decoration:none;
					padding-bottom:20px;
					transition:0.3s;
					&:hover{
						border-bottom:3px solid white;
					}
				}
			}
		}
		.menu-btn{
			display:none;
			position:absolute;
			right:40px;
			top:20px;
			background:none;
			border:none;
			cursor:pointer;
			outline:none;
			span{
				display:block;
				width:40px;
				height:5px;
				margin-bottom:5px;
				background-color:white;
				border:none;
			}
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	header{
		.main-menu{
			max-width:initial;
			ul{
				margin-left:40px;
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	header{
		background-color:transparent;
		.main-menu{
			ul{
				display:none;
			}
			.menu-btn{
				display:block;
			}
		}
	}
	.pushy-open-right{
		header{
			.main-menu{
				.menu-btn{
					height:35px;
					span{
						background-color:black;
						&:nth-of-type(1){
							transform:rotateZ(45deg);
						}
						&:nth-of-type(2){
							margin-top:-10px;
							transform:rotateZ(-45deg);
						}
						&:last-of-type{
							display:none;
						}
					}
				}
			}
		}
	}
}
/*RWD SMALL*/
@media #{$small}{
	.cookies{
		.container{
			.info{
				width:100%;
				padding-bottom:30px;
				border-right:0px;
			}
		}
	}
	header{
		.main-menu{
			ul{
				display:none;
			}
		}
	}
}
/*RWD EXTRASMALL*/
@media #{$extra-small}{
	header{
		padding-bottom:40px;
	}
}