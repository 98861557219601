main{
	.gallery-wrapper{
		margin-top:40px;
		.gallery-list{
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			margin-top:10px;
			.gallery-item{
				display:block;
				position:relative;
				margin:10px;
				font-size:0;
				overflow:hidden;
				&:after{
					position:absolute;
					content:'';
					width:100%;
					height:100%;
					left:-100%;
					top:0;
					background-color:rgba(233, 53, 46, 0.8);
					background-image:url(../images/zoom-icon.png);
					background-repeat:no-repeat;
					background-position:center;
					transition:left .3s;
				}
				&:hover{
					&:after{
						left:0;
					}
				}
				img{
					width:250px;
					height:200px;
					object-fit:cover;
				}
			}
		}
	}
}

/*RWD MEDIUM*/
@media #{$medium}{
	main{
		
	}
}