main{
	.contact-page{
		margin-top:70px;
		.cotact-box{
			display:flex;
			flex-direction:row;
			.contact-icons-box{
				display:flex;
				flex-direction:row;
				flex-wrap:wrap;
				width:60%;
				height:200px;
				.contact-icon{
					display:flex;
					flex-direction:row;
					align-items:center;
					font-size:17px;
					color:#021350;
					margin:10px;
					max-width:300px;
					width:100%;
					height:80px;
					img{
						width:70px;
						height:70px;
						object-fit:scale-down;
						margin-right:30px;
					}
					&:nth-of-type(2n+1){
						margin-left:0;
					}
				}
			}
			.contact-form{
				display:flex;
				flex-direction:column;
				width:40%;
				input, textarea{
					padding:10px 15px;
					margin:5px 0;
					border:1px solid #c1dae9;
				}
				input::placeholder{
					color:#021350;
				}
				textarea{
					min-height:100px;
					&::placeholder{
						color:#021350;
					}
				}
				.rodo{
					display:block;
					margin:10px 0;
					font-size:11px;
					input{
						margin:0;
					}
					a{
						font-size:11px;
						color:red;
						text-decoration:none;
					}
				}
				.btn-g{
					margin-top:30px;
					margin-right:30px;
					align-self:flex-end;
				}
			}
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	main{
		.contact-page{
			.cotact-box{
				.contact-icons-box{
					height:initial;
				}
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	main{
		.contact-page{
			margin-top:40px;
		}
	}
}
/*RWD SMALL*/
@media #{$small}{
	main{
		.contact-page{
			.cotact-box{
				flex-direction:column;
				.contact-icons-box{
					width:100%;
					.contact-icon{
						max-width:250px;
						margin:0;
						font-size:15px;
						img{
							width:40px;
							height:40px;
							margin-right:10px;
						}
					}
				}
				.contact-form{
					width:100%;
					margin-top:30px;
				}
			}
		}
	}
}