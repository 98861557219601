main{
	.subpage-page{
		margin-top:80px;
		.text{
			margin-top:40px;
			.icon{
				float:left;
				margin:0 30px 20px 0;
				width:360px;
				height:243px;
				object-fit:cover;
			}
		}
	}
}

/*RWD MEDIUM*/
@media #{$medium}{
	main{
		.subpage-page{
			.text{
				.icon{
					display:block;
					float:none;
					margin:auto;
				}
			}
		}
	}
}