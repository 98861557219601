/*VARIABLES*/
$large: "(max-width:1199px)";
$medium: "(max-width:991px)";
$small: "(max-width:767px)";
$extra-small: "(max-width:576px)";

/*IMPORT LIBS*/
@import "lib/lightbox.scss";
@import "lib/pushy.scss";
@import "lib/slick.scss";
@import "lib/sweetalert2.scss";

/*IMPORT TEMPLATES*/
@import "global.scss";
@import "templates/header.scss";
@import "templates/slider.scss";
@import "templates/footer.scss";

/*IMPORT VIEWS*/
@import "views/subpage.scss";
@import "views/aboutus.scss";
@import "views/offer.scss";
@import "views/news.scss";
@import "views/contact.scss";
@import "views/gallery.scss";
