main{
	.news-wrapper {
		display:flex;
		justify-content:flex-end;
		width:100%;
		margin-top:130px;
		.news-container {
			display:flex;
			position:relative;
			width:60%;
			height:545px;
			background:#e9352e;
			&:after{
				position:absolute;
				content:'';
				width:518px;
				height:674px;
				left:-405px;
				top:-80px;
				background-image:url(../images/news-wrapper.png);
				background-repeat:no-repeat;
			}
			.content {
				position:relative;
				max-width:555px;
				width:100%;
				margin:80px 0 0 140px;
				padding:0 20px;
				.nav {
					display:flex;
					justify-content: space-between;
					align-items: center;
					width:100%;
					z-index:1;
					margin-bottom:30px;
					a {
						color:white;
						font-size:13px;
						text-decoration:none;
					}
				}
				.news {
					.news-icon{
						max-width:555px;
						width:100%;
						height:147px;
						object-fit:cover;
					}
					.date {
						color:white;
						font-size:14px;
						font-weight:300;
						margin-top:30px;
					}
					.title {
						display:block;
						color:white;
						font-size:16px;
						font-weight:bold;
						text-decoration:none;
						margin:5px 0 20px;
					}
					.text {
						font-size:15px;
						color:white;
					}
					.news-readmore{
						display:block;
						font-size:15px;
						color:white;
						text-decoration:none;
						margin-top:15px;
					}
				}
			}
		}
	}
	.news-list-page{
		margin-top:80px;
		.news-list{
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			justify-content:space-between;
			margin-top:20px;
			.news{
				width:355px;
				margin:25px 0;
				color:#021350;
				.icon{
					display:block;
					margin-bottom:30px;
					font-size:0;
					border:1px solid #e1d0cf;
					img{
						width:353px;
						height:147px;
						object-fit:cover;
					}
					&.default{
						img{
							object-fit:contain;
						}
					}
				}
				.date{
					font-size:14px;
				}
				.title{
					display:block;
					font-size:18px;
					font-weight:bold;
					color:#021350;
					text-decoration:none;
					margin:5px 0 10px;
				}
				.content{
					font-size:16px;
				}
				.readmore{
					display:block;
					font-size:16px;
					color:#021350;
					text-decoration:none;
					margin-top:10px;
				}
			}
		}
	}
	.news-page{
		margin-top:80px;
		.text{
			margin-top:40px;
			.icon{
				float:left;
				margin:0 30px 20px 0;
				width:360px;
				height:243px;
				object-fit:cover;
			}
		}
	}
}

/*RWD LARGE*/
@media #{$large}{
	main{
		.news-wrapper{
			.news-container{
				&:after{
					width:435px;
					height:575px;
					left:-460px;
					top:0px;
					background-size:100%;
				}
				.content{
					margin-left:40px;
				}
			}
		}
		.news-list-page{
			.news-list{
				justify-content:center;
				.news{
					margin:25px 10px;
				}
			}
		}
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	main{
		.news-wrapper{
			margin-top:40px;
			.news-container{
				width:100%;
				&:after{
					display:none;
				}
				.content{
					margin:auto;
				}
			}
		}
		.news-page{
			.text{
				.icon{
					display:block;
					float:none;
					margin:auto;
				}
			}
		}
	}
}