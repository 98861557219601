main{
	.aboutus-wrapper{
		display:flex;
		justify-content: space-between;
		margin-top:80px;
		.content{
			max-width:625px;
			width:100%;
			.text{
				color:#021350;
				font-size:16px;
				font-weight:400;
				padding-top:20px;
				line-height:1.5;
				width:90%;
			}
			.btn-more{
				height:40px;
				display:flex;
				justify-content: center;
				align-items: center;
				padding-left:25px;
				padding-right:25px;
				background:#e9352e;
				color:white;
				width:fit-content;
				text-decoration: none;
				font-weight:500;
				position:relative;
				margin-top:40px;
				&:before {
					position:absolute;
					content:'';
					height:28px;
					width:30px;
					background:#e9352e;
					transform: rotate(45deg);
					right:-15px;
					z-index:-1;;
				}
			}
		}
		.icon-wrapper{
			display:flex;
			flex-wrap:wrap;
			max-width:455px;
			width:100%;
			max-height:350px;
			padding-top:40px;
			.box{
				max-width:200px;
				width:100%;
				display:flex;
				justify-content:center;
				align-items:center;
				flex-direction:column;
				padding-bottom:30px;
				.text{
					color:#021350;
					font-size:13px;
					font-weight:bold;
					width:165px;
					text-align:center;
					padding-top:20px;
					text-transform:uppercase;
				}
			}
		}
	}
}

/*RWD MEDIUM*/
@media #{$medium}{
	main{
		.aboutus-wrapper{
			flex-direction:column;
			justify-content:center;
			align-items:center;
			margin-top:40px;
			.icon-wrapper{
				padding-top:50px;
			}
		}
	}
}