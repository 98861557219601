body{
	font-family:'Poppins', sans-serif;
	margin:0 auto;
	overflow-x:hidden;
}
.container{
	position:relative;
	max-width:1140px;
	width:100%;
	margin-left:auto;
	margin-right:auto;
	padding-left:15px;
	padding-right:15px;
	box-sizing:border-box;
}
.btn-g{
	display:flex;
	position:relative;
	justify-content: center;
	align-items: center;
	width:fit-content;
	height:40px;
	margin-top:40px;
	padding-left:25px;
	padding-right:25px;
	color:white;
	font-weight:500;
	text-decoration: none;
	background:#e9352e;
	border:none;
	cursor:pointer;
	&:before {
		position:absolute;
		content:'';
		height:28px;
		width:30px;
		background:#e9352e;
		transform: rotate(45deg);
		right:-15px;
		z-index:-1;;
	}
	&.btn-white{
		color:#e9352e;
		font-weight:bold;
		background:white;
		&:before{
			background:white;
		}
	}
}
.title-g{
	color:#e9352e;
	font-size:30px;
	font-weight:bold;
	&.title-white{
		color:white;
	}
}
.content-g{
	margin:20px 0 40px;
}
.clear-g{
	clear:both;
}

/*RWD LARGE*/
@media #{$large}{
	.container{
		max-width:960px;
	}
}
/*RWD MEDIUM*/
@media #{$medium}{
	.container{
		max-width:720px;
	}
}
/*RWD SMALL*/
@media #{$small}{
	.container{
		max-width:540px;
	}
}